// import { addDays } from 'date-fns'

const state = {

    bookings: [],
    company:{},
    settings:{
        referral_program_enabled: false
    },
    payment_methods:{},
    customer: {},
    stripeKey: '',
    isLoggedIn: null,

}

export default state
