import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [

    {
      path: '/',
      name: 'login',
      component: () => import('../components/login.vue'),
      meta: { requireGuest: true }

    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import ('../views/dashboard.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: () => import('../views/auth/reset-password.vue'),
      meta: { requireGuest: true }
    },
    {
      path: '/verification-sent/:token',
      name: 'verification-sent',
      component: () => import('../views/auth/new-password.vue'),
      meta: { requireGuest: true }
    },
    {
      path: '*',
      component: () => import ('../views/error404.vue')
    }
  ]
})


router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requireGuest)) {

    // console.log('1. ',to)
    if (store.state.isLoggedIn ) {
      // console.log('2. ',to)

        next({
          name: 'dashboard',
        })
      } else {
        next()
      }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if (!store.state.isLoggedIn ) {
      next({
        name: 'login',
        // query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
