const getters = {


    // calendars
    bookings: state => {
        return state.bookings;
    },
    unassignedBookings: (state, getters) => getters.bookings.filter((event)=> event.book_team_id <= 0),
    assignedBookings: (state, getters) => getters.bookings.filter((event)=> event.book_team_id) > 0,
    customer: (state,getters) => ({...state.customer, ...getters.lastBookingAddress, selected_state: getters.lastBookingAddress.state  }),
    paymentMethods: (state) => state.payment_methods,
    company: (state) => JSON.parse(state.company),
    stripeKey: (state) => state.stripeKey,
    companyId: (state,getters) => String(getters.company.id),
    lastBookingAddress: (state,getters) => (state.bookings.length) ? JSON.parse(state.bookings[0].address) : {},
    settings: (state) => state.settings,
  }

  export default getters
