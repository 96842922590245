import * as Mutation from './mutation-types'
import { getEventIndexById } from './utils'
import router from "../router";

const mutations = {

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    SET_LOGIN(state, value) {
        state.isLoggedIn = value;

        if(value)
            router.push({name:'dashboard'})
        else
            if((router.currentRoute.name != 'verification-sent') && (router.currentRoute.name != 'login'))
                router.push({name:'login'})

    },


    SET_PAYMENT_METHODS(state, value) {
        // console.log(value)
        state.payment_methods = value;
    },

    SET_SETTINGS(state, value) {
        state.settings = value;
    },
    SET_COMPANY(state, value) {
        state.company = value;
    },

    SET_STRIPE_KEY(state, value){
        state.stripeKey = value;
    },

    SET_CUSTOMER(state, value) {
        state.customer = value.customer;
    },

    TOGGLE_VIEWMODE(state, value) {

        if(state.viewmode == 'dayGridMonth')
            state.viewmode = 'listWeek';
        else
            state.viewmode = 'dayGridMonth'
    },



    // Calendar
    [Mutation.CREATE_EVENT] (state, event) {
        return state.bookings.push(event)
    },

    [Mutation.UPDATE_EVENT] (state, updatedEvent) {
        const index = getEventIndexById(state, updatedEvent.id)

        if (index === -1) {
            return console.warn(`Unable to book event (id ${updatedEvent.id})`)
        }

        Object.assign(state.bookings[index], {
            ...state.bookings[index],
            title: updatedEvent.title,
            start: updatedEvent.start,
            end: updatedEvent.end,
            date: updatedEvent.date,
            book_team_id: (updatedEvent.extendedProps.assigned)
        })

        // console.log(state.bookings[index])

        return state.bookings
    },
    [Mutation.DELETE_EVENT] (state, eventId) {
        const index = getEventIndexById(state, eventId)

        if (index === -1) {
            return console.warn(`Unable to delete book (id ${eventId})`)
        }

        return state.bookings.splice(index, 1)
    },
    [Mutation.SET_WEEKENDS_ENABLED] (state, enabled) {
        state.weekendsVisible = enabled
    },
    [Mutation.SET_BOOKINGS] (state, bookings) {
        state.bookings = bookings
      }



}

export default mutations
