import * as Mutation from './mutation-types'

const actions = {


    // Asyncimport { addDays } from 'date-fns'

    auth_login({commit,state, getters}, credentials) {

        this._vm.$post('login', {...credentials, member_id: getters.company.id}).then((response)=>  {
            if(response.ok){

                response.json().then((result)=>{
                    commit('SET_CUSTOMER', result);
                    commit('SET_LOGIN', true);
                });
            }else{
                response.json().then((result)=>{
                    this._vm.$notify.error({
                        title  : 'Authentication Error',
                        message: result.error,
                        offset : 100
                    });
                })

            }
        })
    },

    refresh_customer_data({commit,state, getters}, credentials) {

        this._vm.$post('login', {...credentials, member_id: getters.company.id}).then((response)=>  {
            if(response.ok){

                response.json().then((result)=>{
                    commit('SET_CUSTOMER', result)
                    commit('SET_LOGIN', true);

                })
            }
        })
    },

    auth_logout({commit, state}){
        this._vm.$post('logout', {}).then((response)=>  {
            if(response.ok){
                commit('SET_LOGIN', false)
            }
        })
    },

    assignBook ({ commit,state }, selectedBooking){

    },

    // Calendar
    createEvent ({ commit }, event) {
        return commit(Mutation.CREATE_EVENT, event)
    },
    updateEvent ({ commit }, updatedEvent) {
        return commit(Mutation.UPDATE_EVENT, updatedEvent)
    },
    deleteEvent ({ commit }, eventId) {
        return commit(Mutation.DELETE_EVENT, eventId)
    },
    setweekendsVisible ({ commit }, enabled) {
        return commit(Mutation.SET_WEEKENDS_ENABLED, enabled)
    },

    setBookings ({ commit }) {

        this._vm.$get(`booking`).then((res) => {
            if (res.ok)
                res.json().then((result) => commit('SET_BOOKINGS',  result.data))
        });
    },

    loadPaymentMethods({ commit }) {

        this._vm.$get(`payments`).then((res) => {
            if (res.ok)
                res.json().then((result) => commit('SET_PAYMENT_METHODS',  result.payment_methods))
        });
    },


    setSettings({ commit }, settings) {
        return commit('SET_SETTINGS', settings)
    },

    setCompany({ commit }, company) {
        return commit('SET_COMPANY', company)
    },

    setStripeKey({ commit }, stripe) {
        return commit('SET_STRIPE_KEY', stripe)
    }


}

export default actions
